import React from "react";

export default function Accessories1(props) {
    return(
        <div className="accessories1">
            <img className="accessImg" src={props.img} alt="" />
            <span className="accessName">{props.name}</span>
            <span className="accessChips">{props.Chips}</span>
        </div>
    )
}