import React from "react";


export default function Header() {
    return (
        <div className="nav">
            <nav>
                <ul>
                    <h1 className="Head">AKAZA <span>STORE</span></h1>
                    <li className="lii"><a href="Home.html">Home</a></li>
                    <li className="li"><a href="about.html">Shop</a></li>
                    <li className="li"><a href="Contact.html">Features</a></li>
                    <li className="li"><a href="Contact.html">Blog</a></li>
                    <li className="li"><a href="Contact.html">About</a></li>
                    <li className="li"><a href="Contact.html">Contact</a></li>
                </ul>
                <img src="" alt="" />
            </nav>
            <div className="Main">
                <h1 className="h1">Women Collection 2022</h1>
                <h1 className="h11">NEW SEASON</h1>
                <button className="btn">SHOP NOW</button>
            </div>
        </div>
    )
}