import React from "react";

export default function Main (props) {
    return (
        <div className="card">
            <div className="write">
                <h1 className="cardname">{props.gender}</h1>
                <p className="season">{props.season} {props.year}</p>
            </div>
            <img className="cardimg" src={props.img} alt="" />
        </div>
    )
}