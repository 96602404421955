export default[
    {
        id : 1,
        img : "product-09.jpg",
        name : "Converse All Star hi Plimsolls",
        Chips : "$75.00"
    },
    {
        id : 2,
        img : "product-10.jpg",
        name : "Femme T-Shirt in Stripe",
        Chips : "$25.86"
    },
    {
        id : 3,
        img : "product-11.jpg",
        name : "Herchel Supply",
        Chips : "$63.16"
    },
    {
        id : 4,
        img : "product-12.jpg",
        name : "Herchel Supply Winter",
        Chips : "$66.23"
    },
]