import Header from './components/Header';
import Main from './components/Main';
import Data from './components/Data';
import SecondFront from './components/SecondFront';
import Overview from './components/Overview';
import Accessories1 from './components/Accessories1';
import AccessData from './components/AccessData';
import Accessories2 from './components/Accessories2';
import AccessData2 from './components/AccessData2';
import Accessories3 from './components/Accessories3';
import AccessData3 from './components/AccessData3';
import Accessories4 from './components/Accessories4';
import AccesData4 from './components/AccesData4';
import LoadBtn from './components/LoadBtn';
import Footer from './components/Footer';
import './App.css';

function App() {
  const cards = Data.map((item) => {
    return(
      <Main
      key = {item.id}
      {...item}
     />
    )
    
  })
  const access = AccessData.map((items) => {
    return(
      <Accessories1 
      key = {items.id}
      {...items}
      />
    )
  })
  const access2 = AccessData2.map((itm) => {
    return(
      <Accessories2 
      key= {itm.id}
      {...itm}
      />
    )
  })
  const access3 = AccessData3.map((item) => {
    return(
      <Accessories3
      key={item.id}
      {...item}
      />
    )
  })
  const access4 = AccesData4.map((item) => {
    return(
      <Accessories4
      key = {item.id}
      {...item}
      />
    )
  })
  return (
    <div className="App">
     <Header />
     <div className="cards">
      {cards}
     </div>
     <SecondFront />
     <Overview />
      <div className="access">
        {access}
      </div>
      <div className="access2">
        {access2}
      </div>
      <div className="access3">
        {access3}
      </div>
      <div className="access4">
        {access4}
      </div>
      <div className="button">
        <LoadBtn />
      </div>
     <Footer />
    </div>
  );
}

export default App;
