export default [
    {
        id : 1,
        img : "product-05.jpg",
        name : "Front Pocket Jumper",
        Chips : "$34.75"
    },
    {
        id : 2,
        img : "product-06.jpg",
        name : "Vintage Inspired Classic",
        Chips : "$93.20"
    },
    {
        id : 3,
        img : "product-07.jpg",
        name : "Shirt in Stretch Cotton",
        Chips : "$52.66"
    },
    {
        id : 4,
        img : "product-08.jpg",
        name : "Pieces Metalic Printed",
        Chips : "$18.96"
    },
]