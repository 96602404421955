import React from "react";



export default function Overview() {
    return(
        <div className="overview">
            <h1 className="over">PRODUCT OVERVIEW</h1>
            <div className="nab">
                <ul className="second-ul">
                    <li className="nabb">All Products<a href=""></a></li>
                    <li className="nabb"><a href="">Women</a></li>
                    <li className="nabb"><a href="">Men</a></li>
                    <li className="nabb"><a href="">Bag</a></li>
                    <li className="nabb"><a href="">Shoes</a></li>
                    <li className="nabb"><a href="">Watches</a></li>
                </ul>
            </div>
        </div>
    )
}