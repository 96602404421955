export default[
    {
        id : 1,
        img : "product-01.jpg",
        name : "Esprit Ruffle Shirt",
        Chips : "$16.64"
    },
    {
        id : 2,
        img : "product-02.jpg",
        name : "Herschel supply",
        Chips : "$35.31"
    },{
        id : 3,
        img : "product-03.jpg",
        name : "Only Checker Trouser",
        Chips : "$25.50"
    },{
        id : 4,
        img : "product-04.jpg",
        name : "Classic Trench Court",
        Chips : "$75.00"
    }
]