export default[
    {
        id : 1,
        img : "banner-01.jpg",
        gender : "Women",
        season : "Spring",
        year : "2022",
        
    },
    {
        id : 2,
        img : "banner-02.jpg",
        gender : "Men",
        season : "Spring",
        year : "2022",

    },
    {
        id : 3,
        img : "banner-03.jpg",
        gender : "Accessories",
        season : "New",
        year : "Trend"

    }
]