import React from "react";


export default function SecondFront() {
    return(
        <div className="secFront">
            <div className="con">
                <h1 className="h1">Men Collection 2022</h1>
                <h1 className="h11">NEW ARRIVALS</h1>
                <button className="btn">SHOP NOW</button>
            </div> 
        </div>
    )
}