export default[
    {
        id : 1,
        img : "product-13.jpg",
        name : "T-Shirt with Sleeve",
        Chips : "$18.49"
    },
    {
        id : 2,
        img : "product-14.jpg",
        name : "Pretty Little Thing",
        Chips : "$54.79"
    },
    {
        id : 3,
        img : "product-15.jpg",
        name : "Mini Silver Mash Watch",
        Chips : "$86.85"
    },
    {
        id : 4,
        img : "product-16.jpg",
        name : "Square Neck Back",
        Chips : "$29.64"
    },
    
]